/* Admin.css */


/* Login Page styles */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #545454, #2d2d2d);
}

.login-form {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 30vw;
}

.login-form h2 {
    margin-bottom: 1rem;
    color: #626262;
}

.input-group {
    margin-bottom: 1rem;
}

.input-group input {
    width: 90%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.login-button {
    background: #626262;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.login-button:hover {
    background: #4a4a4a;
}

/* Admin Page styles */
.admin-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.admin-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #626262;
    color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.admin-navbar ul {
    list-style: none;
    display: flex;
    gap: 1rem;
    padding: 0;
    margin: 0;
}

.admin-navbar li {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
    position: relative;
}

.admin-navbar li::before, .admin-navbar li::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    background: white;
    transition: width 0.3s;
}

.admin-navbar li::before {
    top: 0;
    left: 50%;
}

.admin-navbar li::after {
    bottom: 0;
    right: 50%;
}

.admin-navbar li::before, .admin-navbar li::after {
    width: 100%;
    left: 0;
    right: 0;
}

.logout-button {
    background: white;
    color: #626262;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.logout-button:hover {
    background: #d3d3d3;
}

.admin-content {
    flex-grow: 1;
    padding: 2rem;
    background: #f4f4f4;
    text-align: center;
}

.admin-content h1 {
    margin-bottom: 1rem;
    color: #626262;
}

.admin-content p {
    color: #333;
}
.admin-navbar li:hover {
    transform: translateY(-3px);
}
.AdminNaptarHolder {
    width: 80vw;
    margin-top: 7.5vh;
    margin-left: 10vw;
}
/* ReservationsList.css */

.reservations-container {
    padding: 2rem;
    background: #f4f4f4;
}

.reservations-container h2 {
    text-align: center;
    color: #626262;
}

.reservations-list-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 1rem;
}

.reservations-list-header {
    display: flex;
    width: max-content;
}
.reservations-list {
    display: flex;
    width: max-content;
    flex-direction: column;
    max-width: 3010px;
}

.reservations-list-header {
    background: #626262;
    color: white;
    padding: 0.5rem;
    width: 3010px;
}

.reservation-label, .reservation-item > div {
    flex: 0 0 250px; /* Fixed width for each column */
    padding: 0.5rem;
    border-right: 1px solid #ddd;
    box-sizing: border-box;
    white-space: normal; /* Allow text to wrap */
    max-height: 40px;
    overflow: hidden;
    color: black;
}

.reservation-item {
    display: flex;
    padding: 0.5rem;
    border-bottom: 1px solid #ddd;
    width: max-content;
    max-width: 3010px;
    min-width: 3010px;
}

.pagination-controls {
    display: flex;
    margin-top: 1rem;
    max-width: 80%;
    overflow-x: scroll;
    margin-left: 10%;
}

.pagination-controls button {
    background: #626262;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.pagination-controls button:hover {
    background: #4a4a4a;
}

.pagination-controls button.active {
    background: #4a4a4a;
}

.pagination-controls button:disabled {
    background: #ddd;
    cursor: not-allowed;
}

/* Modal styles */
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    text-align: center;
}

.close-buttona {
    color: white;
    float: right;
    font-size: 100px;
    font-weight: bold;
    transform: translate(-2vw, 2vh);
}

.close-buttona:hover,
.close-buttona:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
/* Common button styles */
.button-group button {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease, transform 0.2s ease;
}

/* Delete button styles */
.delete-button {
    background-color: #e74c3c;
}

.delete-button:hover {
    background-color: #c0392b;
    transform: scale(1.1);
}

/* Buy button styles */
.buy-button {
    background-color: #2ecc71;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.buy-button:hover {
    background-color: #27ae60;
    transform: scale(1.1);
}

.keszlet-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.keszlet-section {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #ddd;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.keszlet-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkbox-container label {
    font-weight: bold;
    color: #333;
}

.color-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.color-display {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #ddd;
}

.number-input-container input {
    width: 120px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border 0.3s ease;
    color: black;
}

.number-input-container input:focus {
    border: 1px solid #007bff;
}

/* toastStyles.css */
.toast-success {
    background-color: #4caf50 !important;
    color: white !important;
}

.toast-error {
    background-color: #f44336 !important;
    color: white !important;
}

.Toastify__toast {
    border-radius: 8px !important;
    padding: 16px !important;
}

.Toastify__toast-body {
    font-family: Arial, sans-serif !important;
    font-size: 16px !important;
}