:root {
  --dark: #2e2a27;
  --light: #e8e2da;
}

.w-commerce-commercecheckoutformcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercelayoutcontainer {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.w-commerce-commercelayoutmain {
  flex: 0 800px;
  margin-right: 20px;
}

.w-commerce-commercecartapplepaybutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  height: 38px;
  min-height: 30px;
  margin-bottom: 8px;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  height: 38px;
  margin-bottom: 8px;
  padding: 0 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercequickcheckoutgoogleicon, .w-commerce-commercequickcheckoutmicrosofticon {
  margin-right: 8px;
  display: block;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutblockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 20px;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
}

.w-commerce-commercecheckoutcolumn {
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.w-commerce-commercecheckoutshippingcity {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}

.w-commerce-commercecheckoutshippingmethoditem {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 0;
  padding: 16px;
  font-weight: 400;
  display: flex;
}

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 64px 16px;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus, .w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus, .w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus, .w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  flex-direction: row;
  display: flex;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.w-commerce-commercecheckoutbillingaddresstogglelabel {
  margin-left: 8px;
  font-weight: 400;
}

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: flex;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 16px;
}

.w-commerce-commercecheckoutorderitemquantitywrapper {
  white-space: pre-wrap;
  display: flex;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  flex: 0 0 320px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem, .w-commerce-commercecheckoutordersummaryextraitemslistitem {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutplaceorderbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.w-commerce-commercecheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem, .w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: .33em;
}

.w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-commerce-commerceorderconfirmationcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercecheckoutshippingsummarywrapper {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .w-commerce-commercelayoutcontainer {
    flex-direction: column;
    align-items: stretch;
  }

  .w-commerce-commercelayoutmain {
    flex-basis: auto;
    margin-right: 0;
  }

  .w-commerce-commercelayoutsidebar {
    flex-basis: auto;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecheckoutemailinput, .w-commerce-commercecheckoutshippingfullname, .w-commerce-commercecheckoutshippingstreetaddress, .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutrow {
    flex-direction: column;
  }

  .w-commerce-commercecheckoutshippingcity, .w-commerce-commercecheckoutshippingstateprovince, .w-commerce-commercecheckoutshippingzippostalcode, .w-commerce-commercecheckoutshippingcountryselector, .w-commerce-commercecheckoutcardnumber, .w-commerce-commercecheckoutcardexpirationdate, .w-commerce-commercecheckoutcardsecuritycode, .w-commerce-commercecheckoutbillingfullname, .w-commerce-commercecheckoutbillingstreetaddress, .w-commerce-commercecheckoutbillingstreetaddressoptional, .w-commerce-commercecheckoutbillingcity, .w-commerce-commercecheckoutbillingstateprovince, .w-commerce-commercecheckoutbillingzippostalcode, .w-commerce-commercecheckoutbillingcountryselector {
    font-size: 16px;
  }
}

body {
  background-color: white;
  color: var(--light);
  font-family: Monotype helvetica now display, sans-serif;
  font-size: 1vw;
  font-weight: 500;
  line-height: 1.6;
}

h1 {
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12.08em;
  font-weight: 700;
  line-height: .8;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.3em;
  font-weight: 700;
  line-height: 1.4;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.7em;
  font-weight: 700;
  line-height: 1.4;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.3em;
  font-weight: 700;
  line-height: 1.4;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.4;
}

h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: .85em;
  font-weight: 700;
  line-height: 1.4;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1em;
}

a {
  font-size: 1em;
  text-decoration: none;
}

blockquote {
  border-left: .4em solid #e2e2e2;
  margin-bottom: 1em;
  padding: 1em 2em;
  font-size: 140%;
  line-height: 1.6;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 2.08em;
}

.container.is--nav {
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.container.is--header {
  text-align: center;
  background-color: #fff;
  padding-top: 8.78em;
  padding-bottom: 8.78em;
}

.container.is--categories {
  padding-top: 6.18em;
  padding-bottom: 6.18em;
}

.container.is--grid {
  padding-bottom: 0;
}

.html {
  width: 0;
  height: 0;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.section.is--nav {
  z-index: 1000;
  background-color: #fff;
  margin-top: -1px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.section.is--hero {
  background-image: url('../images/IMG_4042-1.png');
  background-position: 50%;
  background-size: cover;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.section.is--grid {
  z-index: 1;
  position: relative;
}

.section.is--catories {
  z-index: 2;
  position: relative;
}

.nav_side {
  z-index: 2;
  text-transform: uppercase;
  width: auto;
  position: relative;
}

.nav_side.is--right {
  justify-content: flex-end;
  align-items: center;
  width: auto;
  display: flex;
}

.nav_logo {
  width: 11.61em;
  position: absolute;
}

.nav_logo-img {
  color: #000;
  width: 100%;
}

.nav_link {
  text-transform: uppercase;
  padding-right: .89em;
}

.nav_link:hover {
  text-decoration: underline;
}

.nav_link-text {
  color: #000;
  font-size: .9rem;
}

.hero_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.nav_top {
  z-index: 1;
  border-bottom: 1px solid var(--light);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 6em;
  padding-top: 2.08em;
  padding-bottom: 2.08em;
  display: flex;
  position: relative;
}

.header_text-move.is--last {
  padding-bottom: 2.4em;
}

.header_text-wrap {
  position: relative;
}

.is--alt-text {
  color: #2e2a27;
  font-family: Monotype helvetica now display lt it, sans-serif;
  font-weight: 400;
}

.sticky-circle {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding-top: 6em;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.sticky-circle_element {
  border-radius: 35em;
  justify-content: center;
  align-items: center;
  width: 35em;
  height: 35em;
  display: flex;
  position: relative;
  overflow: hidden;
}

.sticky-circle_img {
  object-fit: cover;
  width: 100vw;
  min-width: 100vw;
  max-width: none;
  height: 100vh;
  min-height: 100vh;
  position: relative;
}

.sticky-circle_wrap {
  height: 300vh;
}

.categories {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 82em;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.categories_link {
  border-radius: 100vw;
  margin: .4em;
  padding: .6em 1.82em;
  position: relative;
}

.categories_link-text {
  z-index: 2;
  font-size: 3.96em;
  line-height: .8;
  position: relative;
}

.categories_border {
  z-index: 1;
  border: 1px solid var(--light);
  opacity: .35;
  border-radius: 100vw;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.grid_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.grid_item {
  padding: .36em;
}

.grid_element {
  width: 100%;
  padding-top: 131%;
  position: relative;
}

.grid_list {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 3.6em;
  display: flex;
}

.grid_wrapper {
  z-index: 2;
  position: relative;
}

.grid_wrapper.is--alt {
  padding-bottom: 23.7em;
}

.grid_text-wrap {
  z-index: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100vh;
  margin-top: -50vh;
  padding-bottom: 2.08em;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.grid_text-title {
  letter-spacing: -.04em;
  font-size: 21.88em;
  font-weight: 700;
  line-height: .8;
  position: relative;
}

.nav_logo-contain {
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
}

.grid_text-list {
  margin-left: -.5em;
  position: relative;
}

.grid_text-item {
  opacity: 0;
  font-size: 1em;
  transition: opacity .1s;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.heading {
  color: #2e2a27;
}

.heading-2, .heading-3 {
  color: #2e2a27;
}

.hambicont-copy-3 {
  display: block;
  cursor: pointer;
  z-index: 1000; /* Ensure the hamburger icon is always on top */
  position: relative;
}

.tophambi-2, .midhambi-2, .bothambi-2 {
  background-color: #fff;
  width: 30px;
  height: 3px;
  margin-bottom: 5px;
  transition: all 0.3s ease; /* Smooth transition for the menu icon */
}

.menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 0;
  padding: 26px 10px 0px 0px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 999;
  animation: fadeIn 0.5s ease-in-out;
  align-items: flex-end;
}

.nav_link2 {
  margin-bottom: 15px;
  text-align: center;
}

.nav_link-text2 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease; /* Smooth transition for text color */
}

.nav_link-text2:hover {
  color: #7e7e7e; /* Change color on hover */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.telotabletnav {
  display: none;
}

.body {
  background-color: #fff;
}

.rolunkhead {
  color: #2e2a27;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  word-break: normal;
  margin-right: 7vw;
  line-height: 1;
  display: inline-block;
}

.rolunkwrap {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 1.78rem;
  padding-left: 2.08em;
  padding-right: 2.08em;
  display: flex;
}

.spacer {
  background-color: #000;
  width: 100%;
  height: 2px;
}

.kepeksection {
  grid-column-gap: 10vw;
  grid-row-gap: 10vw;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 50px;
  display: flex;
}

.tomisection {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  width: 40%;
  display: flex;
}

.spacer2 {
  background-color: #000;
  width: 2px;
  height: 100%;
}

.htomisection {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  width: 40%;
  height: auto;
  display: flex;
}

.image-2 {
  border-radius: 200%;
  width: 150px;
  max-width: 50%;
  height: 150px;
}

.imageholder {
  border-radius: 20px;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.text-block {
  color: #2e2a27;
  font-size: 1rem;
}

.paragraph {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #2e2a27;
  text-transform: uppercase;
  width: auto;
  font-size: 15px;
  font-weight: 500;
  display: block;
}

.textholders {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  width: 100%;
  display: flex;
}

.spacer3 {
  background-color: #000;
  width: 2px;
  height: 100vh;
}

.imageholder2 {
  border-radius: 20px;
  justify-content: flex-start;
  align-items: center;
  height: 300px;
  display: block;
  overflow: hidden;
}

.paragraph-2 {
  color: #2e2a27;
  text-transform: uppercase;
  font-size: 15px;
}

.marqueecontainer {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
}

.innercont {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.rolunkhead2 {
  color: rgba(46, 42, 39, 0);
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  word-break: normal;
  margin-right: 7vw;
  line-height: 1;
  display: inline-block;
}

.code-embed {
  display: none;
}

.horvtomiinstalink {
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.bwlinkmagyartomi {
  width: 40px;
  min-width: 40px;
  height: 40px;
  max-height: 40px;
}

.termekeinkwrap {
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: white;
}

.termekeink-head {
  color: #2e2a27;
  font-size: 8em;
  line-height: 1;
}

.termekcardwrap {
  border: 1px solid #000;
  border-radius: 10px;
  width: 20%;
  height: auto;
  overflow: hidden;
}

.div-block-2 {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 2.08em;
  padding-left: 2em;
  padding-right: 2.08em;
  display: flex;
}

.vonal1, .vonal4 {
  background-color: #000;
  width: 100%;
  height: 1px;
}

.termekcont {
  width: 100%;
  height: 100%;
  display: flex;
}

.vonal2, .vonal3 {
  background-color: #000;
  width: 1px;
  height: 100%;
}

.termekcard {
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 0%;
  display: flex;
}

.termekkep {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.addictedby {
  color: #72706f;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.tolofesuhead {
  color: #2e2a27;
  margin-bottom: 10px;
  font-size: 1.5rem;
  line-height: 1;
}

.div-block-3 {
  width: 100%;
  height: auto;
}

.termekleiras {
  flex-flow: column;
  width: 100%;
  height: auto;
  padding: 20px;
  display: flex;
}

.addictedtext {
  color: #72706f;
  margin-bottom: 10px;
  font-size: 1rem;
}

.text-block-3 {
  font-size: 14px;
}

.mtomitext {
  color: #72706f;
  font-size: 1rem;
}

.popup-wrap {
  z-index: 9;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.popup {
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 15px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 70%;
  height: 95%;
  margin: auto;
  padding: 30px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.bezaro {
  justify-content: flex-end;
  align-items: flex-start;
  height: 30px;
  margin-bottom: 0;
  display: flex;
}

.bezaroholder {
  z-index: 999999;
  border: 1px solid #000;
  border-radius: 200px;
  padding: 5px;
  position: relative;
}

.ms2-svg {
  z-index: 99999;
  color: #2e2a27;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  position: relative;
}

.slatt {
  height: 100%;
  display: flex;
}

.kepholder {
  z-index: 2147483647;
  width: 40%;
  padding-left: 0;
  position: relative;
}

.leiras {
  width: 60%;
}

.fotermekkep {
  z-index: 1999;
  mix-blend-mode: normal;
  border-radius: 5px;
  width: 90%;
  max-width: 90%;
  height: 60%;
  position: relative;
}

.heading-6 {
  color: #2e2a27;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.text-block-5 {
  color: #2e2a27;
  margin-bottom: 20px;
  font-size: 1.1em;
}

.text-block-6 {
  color: #2e2a27;
  margin-bottom: 30px;
  font-size: 1.1em;
}

.text-block-7 {
  color: #72706f;
  margin-bottom: 20px;
  font-size: 1.1em;
}

.text-block-8 {
  color: #2e2a27;
  margin-bottom: 5px;
}

.szinvalaszto {
  flex-flow: column;
  margin-bottom: 15px;
  display: flex;
}

.div-block-5 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  border: 1px #000;
  border-bottom: 1px solid rgba(114, 112, 111, .28);
  margin-bottom: 0;
  padding-bottom: 7.5px;
  display: flex;
}

.szin1 {
  border: 1px solid rgba(0, 0, 0, .38);
  border-radius: 200%;
  width: 30px;
  height: 30px;
  padding: 5px;
  display: flex;
}

.szin2, .szin3 {
  border: 1px solid rgba(0, 0, 0, .38);
  border-radius: 200%;
  width: 30px;
  height: 30px;
  padding: 5px;
}

.div-block-6 {
  background-color: #b57051;
  border-radius: 200%;
  width: 100%;
  height: 100%;
}

.div-block-7 {
  background-color: #4884c2;
  border-radius: 200%;
  width: 100%;
  height: 100%;
}

.div-block-8 {
  background-color: #bbb;
  border-radius: 200%;
  width: 100%;
  height: 100%;
}

.leveljobb {
  z-index: 9;
  max-width: 25%;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.levelbal {
  z-index: 0;
  max-width: 25%;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.nagyito {
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.code-embed-2 {
  display: none;
}

.round-loope {
  border-radius: 50%;
  display: none;
}

.navbar-logo-center-container {
  z-index: 5;
  background-color: rgba(0, 0, 0, 0);
  width: 1030px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.navbar-logo-center-container.shadow-three {
  width: 100%;
  max-width: 1140px;
}

.container-2 {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-wrapper-three {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar-brand-three {
  z-index: 5;
  position: absolute;
}

.nav-menu-wrapper-three {
  width: 100%;
}

.nav-menu-three {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.nav-menu-block {
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.nav-link {
  color: #1a1b1f;
  letter-spacing: .25px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

.nav-link:hover {
  color: rgba(26, 27, 31, .75);
}

.nav-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-dropdown {
  margin-left: 5px;
  margin-right: 5px;
}

.nav-dropdown-toggle {
  letter-spacing: .25px;
  padding: 5px 30px 5px 10px;
  font-size: 14px;
  line-height: 20px;
}

.nav-dropdown-toggle:hover {
  color: rgba(26, 27, 31, .75);
}

.nav-dropdown-toggle:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-toggle[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-icon {
  margin-right: 10px;
}

.nav-dropdown-list {
  background-color: #fff;
  border-radius: 12px;
}

.nav-dropdown-list.w--open {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-dropdown-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.nav-dropdown-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-link-accent {
  color: #1a1b1f;
  letter-spacing: .25px;
  margin-left: 5px;
  margin-right: 20px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
}

.nav-link-accent:hover {
  color: rgba(26, 27, 31, .75);
}

.button-primary {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  transition: all .2s;
}

.button-primary:hover {
  color: #fff;
  background-color: #32343a;
}

.button-primary:active {
  background-color: #43464d;
}

.button {
  color: #2e2a27;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 1rem;
}

.footer {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  flex-flow: column;
  padding: 3rem 5em 0;
  display: flex;
}

.addictedfooterhead {
  color: #2e2a27;
  font-size: 7em;
  line-height: 1;
  display: inline-block;
}

.footercontainer {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  justify-content: space-between;
  align-items: stretch;
  padding-top: 3em;
  padding-bottom: 3em;
  display: flex;
}

.kontaktbal {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-flow: column;
  width: 50%;
  display: flex;
}

.kontakttext {
  color: #2e2a27;
  text-transform: uppercase;
  font-size: 15px;
}

.kontaktcont {
  border: 1px solid #000;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  padding: 10px;
  display: flex;
}

.kontaktgombtext {
  color: #2e2a27;
  text-transform: uppercase;
  font-size: 13px;
}

.kontaktnyilcont {
  background-color: #2e2a27;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.kontaktnyil {
  max-width: 60%;
  transform: rotate(225deg);
}

.footermid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  border-left: 1px solid #000;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  padding-bottom: 0;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
}

.menutext {
  color: #2e2a27;
  text-transform: uppercase;
  font-size: 16px;
}

.rolunktext, .kapcsolattext, .termekeinktext {
  color: #2e2a27;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
}

.menupontokcont {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  display: flex;
}

.footerjobb {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  border-left: 1px solid #000;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  padding: 0 50px;
  display: flex;
}

.text-block-9 {
  color: #2e2a27;
  text-transform: uppercase;
  font-size: 16px;
}

.div-block-11 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.text-block-10 {
  color: #2e2a27;
  text-align: left;
  text-transform: uppercase;
  font-size: 15px;
}

.text-block-11 {
  color: #2e2a27;
  text-transform: uppercase;
  width: 100%;
  font-size: 15px;
}

.licensz {
  padding: 1em 5em .5em;
}

.text-block-12 {
  color: #2e2a27;
  text-transform: uppercase;
  font-size: 13px;
}

.text-block-13 {
  color: #2e2a27;
  text-transform: uppercase;
  font-size: 15px;
}

.leirasholder {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  display: flex;
}

.magyartomihead, .horvtomihead {
  color: #2e2a27;
}

.magyartomiinstalink {
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.termekeinkhead {
  color: #2e2a27;
  font-size: 8em;
}

.htomikep {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .container {
    padding-left: 3em;
    padding-right: 3em;
  }

  .section.is--hero {
    background-image: url('../images/IMG_4042-1.png');
    background-position: 50%;
    background-size: auto;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .nav_link-text {
    display: none;
  }

  .sticky-circle_element {
    width: auto;
    height: auto;
  }

  .nav_logo-contain {
    display: none;
  }

  .hambicont-copy-3 {
    display: block;
  }

  .tophambi-2, .midhambi-2 {
    background-color: #fff;
    width: 30px;
    height: 3px;
    margin-bottom: 5px;
    padding-bottom: 0;
    padding-right: 0;
  }

  .bothambi-2 {
    background-color: #fff;
    width: 30px;
    height: 3px;
    padding-bottom: 0;
    padding-right: 0;
  }

  .telotabletnav {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .image {
    width: 150px;
  }

  .tomisection, .htomisection {
    width: 50%;
  }

  .termekcardwrap {
    width: 30%;
  }

  .container-2 {
    max-width: 728px;
  }

  .navbar-wrapper-three {
    justify-content: space-between;
  }

  .navbar-brand-three {
    position: relative;
  }

  .nav-menu-wrapper-three {
    background-color: rgba(0, 0, 0, 0);
    top: 70px;
  }

  .nav-menu-three {
    background-color: #fff;
    border-radius: 50px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    box-shadow: 0 8px 50px rgba(0, 0, 0, .05);
  }

  .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .menu-button {
    padding: 12px;
  }

  .menu-button.w--open {
    color: #fff;
    background-color: #a6b1bf;
  }

  .footer {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }

  .footercontainer {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    border-bottom-style: none;
    flex-flow: column;
  }

  .kontaktbal {
    width: 100%;
    padding-bottom: 20px;
  }

  .footermid {
    border-top: 1px solid #000;
    border-left-style: none;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
  }

  .rolunktext {
    text-align: left;
  }

  .footerjobb {
    border-top: 1px solid #000;
    border-left-style: none;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .rolunkwrap {
    padding-bottom: 4rem;
  }

  .kepeksection {
    flex-flow: column;
  }

  .tomisection, .htomisection {
    width: 100%;
  }

  .termekcardwrap {
    width: 40%;
  }

  .popup-wrap {
    display: flex;
  }

  .popup {
    z-index: 9999;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
    width: 90%;
    height: auto;
    padding: 20px;
  }

  .slatt {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-flow: column;
  }

  .kepholder {
    width: 100%;
  }

  .leiras {
    z-index: 99999999;
    width: 100%;
    position: relative;
  }

  .fotermekkep {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .heading-6 {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .text-block-5, .text-block-6 {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 1.4;
  }

  .text-block-7 {
    z-index: 9999999;
    margin-bottom: 5px;
    font-size: 13px;
    position: relative;
  }

  .text-block-8 {
    font-size: 14px;
  }

  .szinvalaszto {
    margin-bottom: 5px;
  }

  .div-block-5 {
    padding-bottom: 5px;
  }

  .levelbal {
    z-index: 0;
    top: 0%;
  }

  .navbar-brand-three {
    padding-left: 0;
  }

  .nav-menu-three {
    border-radius: 20px;
    flex-direction: column;
    padding-bottom: 30px;
  }

  .nav-menu-block {
    flex-direction: column;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .nav-dropdown {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px rgba(0, 0, 0, .05);
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .nav-link-accent {
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .button {
    font-size: 13px;
  }
}

@media screen and (max-width: 479px) {
  .container.is--nav {
    padding-left: 10px;
    padding-right: 10px;
    display: none;
  }

  .container.is--header {
    padding-left: 10px;
    padding-right: 10px;
  }

  .section.is--hero {
    background-image: url('../images/IMG_4042-1.png');
    background-position: 50%;
    background-size: cover;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding-top: 30px;
    padding-right: 10px;
    display: flex;
  }

  .nav_side, .nav_side.is--right {
    display: none;
  }

  .nav_logo {
    width: 60%;
  }

  .nav_logo-img {
    display: none;
  }

  .hero_img {
    display: block;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .nav_top {
    height: auto;
    min-height: auto;
    padding-top: 7em;
  }

  .is--alt-text {
    font-size: 4rem;
    line-height: 1;
  }

  .sticky-circle_element {
    width: auto;
    height: auto;
  }

  .nav_logo-contain {
    justify-content: flex-end;
    align-items: center;
  }

  .heading {
    font-size: 4rem;
  }

  .heading-2, .heading-3 {
    font-size: 4rem;
    line-height: 1;
  }

  .hambicont-copy-3 {
    z-index: 9999999;
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0;
    display: block;
    position: relative;
  }

  .tophambi-2, .midhambi-2, .bothambi-2 {
    background-color: #fff;
    width: 30px;
    height: 2px;
    margin-bottom: 5px;
    padding-bottom: 0;
    padding-right: 0;
  }

  .telotabletnav {
    width: 100%;
  }

  .rolunkwrap {
    padding-left: 10px;
    padding-right: 10px;
  }

  .kepeksection {
    grid-column-gap: 70px;
    grid-row-gap: 70px;
    flex-flow: column;
    margin-top: 15px;
  }

  .tomisection, .htomisection {
    width: 100%;
  }

  .paragraph {
    width: 100%;
    font-size: 14px;
  }

  .textholders {
    flex-flow: column;
  }

  .spacer3 {
    width: 100%;
    height: 2px;
  }

  .paragraph-2 {
    text-align: left;
    font-size: 14px;
  }

  .termekeinkwrap {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    padding-bottom: 40px;
  }

  .termekeink-head {
    font-size: 10em;
  }

  .termekcardwrap {
    width: 250px;
  }

  .div-block-2 {
    justify-content: center;
    align-items: center;
    padding-left: 2em;
  }

  .tolofesuhead {
    font-size: 1.2rem;
  }

  .addictedtext, .mtomitext {
    font-size: .9rem;
  }

  .popup-wrap {
    z-index: 9999999;
    display: none;
  }

  .popup {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 95%;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .slatt {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
  }

  .kepholder {
    width: 100%;
    padding-left: 0;
  }

  .leiras {
    width: 100%;
    margin-top: 10px;
  }

  .fotermekkep {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .heading-6 {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .text-block-5, .text-block-6 {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 1.3;
  }

  .text-block-7 {
    margin-bottom: 5px;
    font-size: 13px;
  }

  .text-block-8 {
    font-size: 14px;
  }

  .szinvalaszto {
    margin-bottom: 5px;
  }

  .leveljobb {
    bottom: auto;
  }

  .container-2 {
    max-width: none;
  }

  .nav-menu-three {
    flex-direction: column;
  }

  .button {
    font-size: 13px;
  }

  .footer {
    padding-top: 3rem;
  }

  .addictedfooterhead {
    font-size: 8em;
  }

  .footercontainer {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-flow: column;
    padding-top: 20px;
  }

  .kontaktbal {
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 20px;
  }

  .kontakttext {
    font-size: 13px;
  }

  .kontaktcont {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    width: 83%;
    padding: 5px;
  }

  .footermid {
    border-top: 1px solid #000;
    border-left-style: none;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 20px 0;
  }

  .menutext {
    font-size: 15px;
  }

  .rolunktext, .kapcsolattext, .termekeinktext {
    text-align: left;
    font-size: 14px;
  }

  .footerjobb {
    border-top: 1px solid #000;
    border-left-style: none;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 20px 0;
  }

  .text-block-9 {
    font-size: 15px;
  }

  .text-block-10, .text-block-11 {
    font-size: 14px;
  }

  .magyartomihead, .horvtomihead {
    font-size: 20px;
  }
}


@font-face {
  font-family: 'Monotype helvetica now display';
  src: url('../fonts/Monotype----Helvetica-Now-Display-Bold.otf') format('opentype'), url('../fonts/Monotype----Helvetica-Now-Display-XBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Monotype helvetica now display lt it';
  src: url('../fonts/Monotype----Helvetica-Now-Display-Lt-It.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Monotype helvetica now display';
  src: url('../fonts/Monotype----Helvetica-Now-Display-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}