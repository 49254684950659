:root {
  --primary-color: #2e2a27;
  --secondary-color: #ffffff;
  --accent-color: #5f5e5d;
  --transition-speed: 0.3s;
  --background-gradient: linear-gradient(135deg, #5cff47, #058b01);
  --border-radius: 15px;
}
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-container {
    background: var(--secondary-color);
    border: 5px solid var(--primary-color);
    border-radius: 15px;
    width: 70vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    position: relative;
    animation: popup-animation var(--transition-speed) ease-out;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
  
  @keyframes popup-animation {
    from {
      transform: scale(0.5);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .close-button {
    position: absolute;
    top: 14%;
    right: 14.5%;
    background: var(--accent-color);
    border: none;
    color: var(--secondary-color);
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background var(--transition-speed);
    z-index: 1000;
  }
  
  .close-button:hover {
    background: #4a4643;
  }
  
  .popup-content {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }
  
  .popup-left {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .popup-image {
    max-width: 64%;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    max-height: 70%;
    margin-bottom: 20px;
    width: 230px;
    height: 70%;
    min-width: 230px;
  }
  
  .color-selector {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .color-selector label {
    cursor: pointer;
    position: relative;
    color: black;
  }
  
  .color-swatch {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    transition: transform var(--transition-speed);
  }
  
  .color-swatch.red {
    background: #b36d4d;
  }
  
  .color-swatch.green {
    background: #c2c2c2;
  }
  
  .color-swatch.blue {
    background: #4882c0;
  }
  
  .color-selector input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .color-selector input:checked + .color-swatch {
    transform: scale(1.2);
  }
  
  .amount-selector {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .amount-selector input {
    padding: 10px;
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    width: 60px;
    color: #2e2a27;
  }
  
  .popup-right {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
  }
  
  /* Apply styles to the form container */
.form-container {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 30px;
}

/* Scrollbar styling for Webkit browsers (Chrome, Safari) */
.form-container::-webkit-scrollbar {
  width: 12px;
}

.form-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light grey background for the track */
}

.form-container::-webkit-scrollbar-thumb {
  background-color: var(--primary-color); /* Primary color for the scrollbar thumb */
  border-radius: 6px; /* Round corners for the scrollbar thumb */
  border: 3px solid #f1f1f1; /* Padding around the thumb to add some space */
}

.form-container::-webkit-scrollbar-thumb:hover {
  background-color: darken(var(--primary-color), 10%); /* Darken the thumb on hover */
}

/* Scrollbar styling for Firefox */
.form-container {
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: var(--primary-color) #f1f1f1; /* Set the thumb and track colors */
}

/* Darken function for CSS */
@function darken($color, $percent) {
  @return mix(black, $color, $percent);
}
  
  .popup-right form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-x: hidden;
    
  }
  
  .popup-right input {
    padding: 10px;
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    color: var(--primary-color);
    width: 70%;
    margin-left: 30%;
  }
  
  .submit-button {
    padding: 10px;
    border: none;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background var(--transition-speed);
  }
  
  .submit-button:hover {
    background-color: #3b3734;
  }
  .amount-selector label {
    color: black;
  }
  .payment-container{
    width: 100%;
    margin-top: 2%;
  }

  .popup-left-top {
    display: flex;
    width: 70%;
    height: 65%;
    margin-bottom: 10px;
  }
  .popup-left-top2 {
    transform: translateX(35%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10%;
  }
  .arconteneter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .arconteneter:hover {
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .arconteneter div {
    color: #333;
    font-size: 1.2rem;
    font-weight: 500;
  }
  
  .arconteneter div:first-child {
    margin-right: 10px;
  }
  .popup-left-scroll {
    height: 50%;
    width: 100%;
    overflow-y: scroll;
    margin-top: -15%;
  }
  
  /* Scrollbar styling for Webkit browsers (Chrome, Safari) */
  .popup-left-scroll::-webkit-scrollbar {
    width: 12px;
  }
  
  .popup-left-scroll::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey background for the track */
  }
  
  .popup-left-scroll::-webkit-scrollbar-thumb {
    background-color: var(--primary-color); /* Primary color for the scrollbar thumb */
    border-radius: 6px; /* Round corners for the scrollbar thumb */
    border: 3px solid #f1f1f1; /* Padding around the thumb to add some space */
  }
  
  .popup-left-scroll::-webkit-scrollbar-thumb:hover {
    background-color: darken(var(--primary-color), 10%); /* Darken the thumb on hover */
  }
  
  /* Scrollbar styling for Firefox */
  .popup-left-scroll {
    scrollbar-width: thin; /* Make the scrollbar thin */
    scrollbar-color: var(--primary-color) #f1f1f1; /* Set the thumb and track colors */
  }
/* Enhanced styles for the purchase summary */
.purchase-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--secondary-color);
  padding: 20px;
  border-radius: var(--border-radius);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  animation: fadeIn var(--transition-speed) ease-in-out;
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.purchase-summary h2 {
  font-size: 2rem;
  color: var(--accent-color);
  margin-bottom: 20px;
  background: var(--background-gradient);
  -webkit-background-clip: text;
  color: transparent;
}

.purchase-summary p {
  font-size: 1.2rem;
  color: var(--primary-color);
  margin: 10px 0;
}

.purchase-summary p span {
  font-weight: bold;
  color: var(--accent-color);
}

.submit-button {
  padding: 10px 20px;
  border: none;
  background-color: var(--accent-color);
  color: var(--secondary-color);
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background var(--transition-speed);
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #094601;
}
.purchase-summary2 h2 {
  font-size: 2rem;
  color: red;
  margin-bottom: 20px;
  background: red;
  -webkit-background-clip: text;
  color: transparent;
}

.purchase-summary2 p {
  font-size: 1.2rem;
  color: var(--primary-color);
  margin: 10px 0;
}

.purchase-summary2 p span {
  font-weight: bold;
  color: var(--accent-color);
}
.purchase-summary2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--secondary-color);
  padding: 20px;
  border-radius: var(--border-radius);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  animation: fadeIn var(--transition-speed) ease-in-out;
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.POPAMOUNTMIN {
  font-size: 30px;
}
.POPAMOUNTADD {
  font-size: 30px;
}
@media only screen and (max-width: 768px) {
  .popup-container {
    width: 90vw;
  }
  .popup-image {
    max-width: 64%;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    max-height: 50%;
    margin-bottom: 20px;
    width: 120px;
    height: 50%;
    min-width: 120px;
}
.popup-left-top {
  display: flex;
  width: 104%;
  height: 65%;
  margin-bottom: 10px;
}
.popup-left-top2 {
  transform: translate(-103%, 34%) scale(0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10%;
}
.popup-left-scroll {
  width: 60%;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) #f1f1f1;
  transform: translate(40%, -120%);
}
.popup-right {
  flex: 1 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  position: absolute;
  height: 50%;
  margin-top: 75%;
}
.submit-button {
  margin-top: -20px;
}
.popup-right input {
  padding: 10px;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  color: var(--primary-color);
  width: 100%;
  margin-left: 0%;
  font-size: 10px;
}
.POPAMOUNT {
  font-size: 25px;
}
.POPAMOUNTMIN {
  font-size: 50px;
}
.POPAMOUNTADD {
  font-size: 50px;
}
.amount-selector label {
  display: none;
}
.close-button {
  right: 3.5%;
  min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
}
.popup-container {
  margin-top: 15vh;
}
.popup-overlay {
  align-items: flex-start;
}
.header_text {
  transform: translateY(40px);
  height: 0px;
}
}