.heading-2,.is--alt-text,.heading,.heading-3 {
    font-size: 12.08em;
    margin: 0;
    line-height: .8;
}
.marqueecontainer {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
  }
  
  .innercont {
    display: inline-block;
    animation: marquee 20s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-125%);
    }
  }
  
  .rolunkhead {
    font-size: 12em; /* Adjust size as needed */
    display: inline-block;
    white-space: nowrap;
    margin: 0;
  }
  
  .rolunkhead .white-border {
    color: white;
    -webkit-text-stroke: 1px black; /* For Safari and Chrome */
    text-stroke: 1px black; /* For other browsers */
  }
  
  .rolunkhead .black {
    color: black;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .innercont {
      animation: none;
      transform: translateX(0);
    }
  }
  .vasarlas-button {
    background-color: #2e2a27;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 15px;
    font-size: 1em;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    font-size: 20px;
  }
  
  .vasarlas-button:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .vasarlas-button:focus {
    outline: none;
  }
  @media only screen and (max-width: 768px) {
    .popup-container {
      width: 90vw;
    }
    .popup-image {
      max-width: 64%;
      border: 2px solid var(--primary-color);
      border-radius: 10px;
      max-height: 50%;
      margin-bottom: 20px;
      width: 120px;
      height: 50%;
      min-width: 120px;
  }
  .arconteneter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 16px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 3px 0;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.popup-container {
  margin-top: 15vh;
}
.popup-overlay {
  align-items: center;
}
  }